<template>
  <v-dialog v-model="dialogProxy" width="500" persistent>
    <v-card>
      <v-card-title class="H4-Secondary-Center" primary-title>
        {{ sector ? 'Edit Group' : 'Create New Group' }}
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="isValid">
          <v-layout row wrap>
            <v-flex xs10>
              <v-text-field label="Group Name" v-model="name" :rules="nameRules" />
            </v-flex>
            <v-flex xs10>
              <v-select
                label="Country"
                :items="countryListing"
                v-model.trim="country"
                :rules="countryRules"
              />
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" flat @click="clearDialog">Cancel</v-btn>
        <v-btn color="primary" flat @click="createSector" :disabled="!isValid">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from '@/rules';

export default {
  props: ['dialog', 'sector'],
  data: () => ({
    name: '',
    nameRules: [rules.required('Group Name is required'), rules.regex(/^[\w\s\d]*$/)],
    country: '',
    countryRules: [rules.required('Country Required')],
    countryListing: [
      {
        text: 'Canada',
        value: 'CA',
      },
      {
        text: 'United States',
        value: 'US',
      },
    ],
    isValid: true,
  }),
  computed: {
    dialogProxy: {
      get() {
        return this.dialog;
      },
      set(v) {
        this.$emit('update:dialog', v);
      },
    },
  },
  watch: {
    sector(v) {
      if (v) {
        this.name = v.name;
        this.country = v.country;
      }
    },
  },
  methods: {
    async createSector() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (this.sector) {
        try {
          await this.$store.dispatch('sectors/updateSector', {
            ...this.sector,
            name: `${this.name}/${this.country}`,
          });
          this.$toast('Updated Group');
          this.$emit('updated');
          this.clearDialog();
        } catch (err) {
          let msg = 'Failed to update Group';
          if (err && err.response && err.response.data && err.response.data.code === 400.35) {
            msg = 'Group already exists';
          }
          this.$toast.error(msg);
        }
      } else {
        try {
          await this.$store.dispatch('sectors/createSector', {
            name: `${this.name}/${this.country}`,
          });
          this.clearDialog();
          this.$toast('Created new Group');
          this.$emit('updated');
          this.clearDialog();
        } catch (err) {
          let msg = 'Failed to create Group.';
          if (err && err.response && err.response.data && err.response.data.code === 400.35) {
            msg = 'Group already exists';
          }
          this.$toast.error(msg);
        }
      }
    },
    clearDialog() {
      this.$refs.form.resetValidation();
      this.dialogProxy = false;
      this.name = '';
      this.country = '';
    },
  },
};
</script>

<style></style>
